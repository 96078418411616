<template>
  <div class="form-container">
    <div class="mb-4">
      <h1 class="mb-3">Reset your password</h1>
      <p>Reset password to your account. Please keep your credentials safe.</p>
    </div>
    <div class="form-group">
      <label for="">Password</label>
      <input
        v-model="payload.password"
        type="password"
        placeholder="Password"
        class="form-control"
      />
    </div>
    <div class="form-group">
      <label for="">Confirm Password</label>
      <input
        v-model="payload.password_confirmation"
        type="password"
        placeholder="Confirm Password"
        class="form-control"
      />
    </div>
    <b-alert
      v-for="(err, i) in errors"
      :key="i"
      v-model="err.visible"
      class="mt-4"
      variant="danger"
      show
      dismissible
    >
      {{ err.message }}
    </b-alert>
    <div ref="requestLinkBlock" class="d-none">
      <router-link to="/forgot-password">Request for new reset link</router-link>
    </div>
    <div class="form-group m-mt-40">
      <b-button
        block
        variant="tertiary"
        :class="classObject"
        :disabled="btnDisabled"
        @click="resetPassword()"
      >
        {{ processing ? "" : "Save New Password" }} <RingLoader v-if="processing" />
      </b-button>
    </div>
    <div class="row-space justify-content-end">
      <a href="">Log in to your dashboard</a>
    </div>
  </div>
</template>
<script>
import setErrorMixin from "@/mixins/setErrorMixin";
import RingLoader from "@/components/loader/RingLoader";

export default {
  title: "Menaget - Talent Dashboard Reset Password",
  components: { RingLoader },
  mixins: [setErrorMixin],
  data() {
    return {
      payload: {
        password: "",
        password_confirmation: "",
        token: this.$route.query.token,
      },
      errors: [],
    };
  },
  computed: {
    btnDisabled() {
      return this.payload.password === "" || this.payload.password_confirmation === "";
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$route.query?.token) {
        vm.$router.push("/login");
      }
    });
  },
  methods: {
    resetPassword() {
      this.processing = true;
      this.$store
        .dispatch("auth/resetPassword", this.payload)
        .then(() => {
          this.payload.password = this.payload.password_confirmation;
          this.$router.push("/login");
        })
        .catch((err) => {
          const data = err?.response;
          if (data?.status === 422) {
            this.errors = this.getErrors(data.data.errors);
          }
          if (data?.status === 403) {
            this.$refs.requestLinkBlock.classList.add("d-block");
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
